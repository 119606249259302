<template>
  <nav
    class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
    id="layout-navbar"
  >
    <div
      class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
      @click="openSidbar()"
    >
      <a class="nav-item nav-link px-0 me-xl-4">
        <i class="ti ti-menu-2 ti-sm"></i>
      </a>
    </div>

    <div
      class="navbar-nav-right d-flex align-items-center"
      id="navbar-collapse"
    >
      <div class="navbar-nav align-items-center">
        <a
          class="nav-link style-switcher-toggle hide-arrow"
          href="javascript:void(0);"
        >
          <i class="ti ti-sm"></i>
        </a>
      </div>

      <ul class="navbar-nav flex-row align-items-center ms-auto">
        <!-- Style Switcher -->
        <li class="nav-item me-2 me-xl-0">
          <a
            @click="toggleTheme"
            class="nav-link style-switcher-toggle hide-arrow"
          >
            <i
              class="ti ti-md"
              :class="[userTheme === 'dark-theme' ? 'ti-sun' : 'ti-moon-stars']"
            ></i>
            <!-- <i v-if="userTheme === 'dark-theme'" class="ti ti-md  ti-sun"></i>
              <i v-if="userTheme === 'light-theme'" class="ti ti-md ti-moon-stars"></i> -->
          </a>
        </li>
        <!--/ Style Switcher -->
        <!-- User -->
        <li class="nav-item navbar-dropdown dropdown-user dropdown">
          <a
            class="nav-link dropdown-toggle hide-arrow"
            href="javascript:void(0);"
            data-bs-toggle="dropdown"
          >
            <div class="avatar avatar-online">
              <img
                src="../../../public/img/avatars/no-image.jpg"
                alt
                class="h-auto rounded-circle"
              />
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item">
                <div class="d-flex">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar avatar-online">
                      <img
                      src="/img/avatars/no-image.jpg"
                        alt
                        class="h-auto rounded-circle"
                      />
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <span class="fw-semibold d-block"></span>
                  </div>
                </div>
              </a>
            </li>
            <li>
       
              <div class="dropdown-divider"></div>
            </li>
            <li>
              <a class="dropdown-item">
                <i class="ti ti-user-check me-2 ti-sm"></i>
                <span class="align-middle">حساب کاربری</span>
              </a>
            </li>
            <li>
              <div class="dropdown-divider"></div>
            </li>
            <li>
                <a class="dropdown-item" @click="Signout()">
                    <i class="ti ti-logout me-2 ti-sm"></i>
                    <span class="align-middle">خروج</span>
                  </a>
            </li>
          </ul>
        </li>
        <!--/ User -->
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      userTheme: 'dark-theme'
    }
  },
  mounted () {
    const initUserTheme = this.getTheme();
    this.setTheme(initUserTheme);
  },
  methods: {
    openSidbar () {
      var element = document.getElementById('layout-menu')
      element.classList.add('open-menu')
    },
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "dark-theme") {
        this.setTheme("light-theme");
      } else {
        this.setTheme("dark-theme");
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },
    openSidbar() {
      var element = document.getElementById("layout-menu");
      element.classList.add("open-menu");
    },
    dropdowntoggle() {
      this.isActiveDropdown = !this.isActiveDropdown;
      console.log(this.isActiveDropdown);
    },
    Signout() {
      localStorage.clear();
      window.location.href = "/";
    },
  }
}
</script>

<style>
.open-menu {
  transform: translate3d(0, 0, 0) !important;
}
</style>
