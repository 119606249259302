<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
        <router-link to="/" class="app-brand-link bg-transparent">
                <span class="app-brand-logo light">
                            <img src="/img/logo.png" />
                   
                </span>
                <span class="app-brand-logo dark">
                    <img src="/img/logoDark.png" />
                </span>
            </router-link>
    </div>

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1">
      <!-- Page -->
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/" class="menu-link">
          <i class="menu-icon tf-icons ti ti-home"></i>
          <div>خانه</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/Authentication" class="menu-link">
          <i class="menu-icon tf-icons ti ti-lock"></i>
          <div>احراز هویت</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/Wallet" class="menu-link">
          <i class="menu-icon tf-icons ti ti-wallet"></i>
          <div>کیف پول</div>
        </router-link>
      </li>

      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/Transactions" class="menu-link">
          <i class="menu-icon tf-icons ti ti-currency-dollar"></i>
          <div>تراکنش ها</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/BankCards" class="menu-link">
          <i class="menu-icon tf-icons ti ti-credit-card"></i>
          <div>کارت های بانکی</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/MoneyRequest" class="menu-link">
          <i class="menu-icon tf-icons ti ti-report-money"></i>
          <div>درخواست برداشت وجه</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/BuyOrSell/1" class="menu-link">
          <i class="menu-icon tf-icons ti ti-building-store"></i>
          <div>خرید سریع</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/Order" class="menu-link">
          <i class="menu-icon tf-icons ti ti-basket"></i>
          <div>سفارشات</div>
        </router-link>
      </li>
      <li class="menu-item" @click="closeSidebar()">
        <router-link to="/Profile" class="menu-link">
          <i class="menu-icon tf-icons ti ti-lock"></i>
          <div>امنیت</div>
        </router-link>
      </li>
    </ul>
  </aside>
</template>
<script>
export default {
  data() {
    return {
      IsDark: true,
    };
  },
  mounted() {
    this.IsDark = localStorage.getItem("user-theme") == "dark-theme";
  },
  methods: {
    closeSidebar() {
      var element = document.getElementById("layout-menu");
      element.classList.remove("open-menu");
    },
  },
};
</script>

<style>
.menu-vertical {
  transition: 500ms all !important;
}
</style>