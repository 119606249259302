import { createRouter, createWebHistory } from 'vue-router';


const routes = [

    { path: '/', name: 'index', component: () => import('../components/views/Index.vue'),  meta: { requiresAuth: true } },
    { path: '/Login', name: 'login', component: () => import('../components/views/Login.vue') },
    { path: '/Profile', name: 'Profile', component: () => import('../components/views/Profile.vue'),meta: { requiresAuth: true } },
    { path: '/Authentication', component: () => import('../components/views/Authentication.vue'),meta: { requiresAuth: true } },
    { path: '/Wallet', component: () => import('../components/views/Wallet.vue'),meta: { requiresAuth: true } },
    { path: '/Transactions', component: () => import('../components/views/Transactions.vue'),meta: { requiresAuth: true } },
    { path: '/BankCards', component: () => import('../components/views/BankCards.vue'),meta: { requiresAuth: true } },
    //  { path: '/BuyAndSell', component: () => import('../components/views/BuyAndSell.vue'),meta: { requiresAuth: true } },
    { path: '/MoneyRequest', component: () => import('../components/views/MoneyRequest.vue'),meta: { requiresAuth: true } },
    { path: '/BuyOrSell/:BuyOrSell', component: () => import('../components/views/BuyOrSell.vue'),meta: { requiresAuth: true } },
    { path: '/Factor/:id', component: () => import('../components/views/Factor.vue'),meta: { requiresAuth: true } },
    { path: '/Order', component: () => import('../components/views/Order.vue'),meta: { requiresAuth: true } },
    { path: '/Order', component: () => import('../components/views/Profile.vue'),meta: { requiresAuth: true } },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") != null) {
            next()
            return
        }
        next('/Login')
    } else {
        next()
    }
})
export default router
