<template>
    <footer class="content-footer footer bg-footer-theme">
        <div class="container-xxl">
            <div class="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                <div>
                    ©
                    2023
                    , توسعه داده شده توسط ❤️ <a href="https://gbitex.com" target="_blank" class="fw-semibold">GBITEX </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

</script>

<style></style>